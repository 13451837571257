import type { FC, ReactNode } from 'react';
import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { EnAuthGuardBehaviour } from 'src/types/enAuthGuardBehaviour';
import { UserRole } from 'src/api/dto/user/userRole';
import useHasAccess from 'src/hooks/useHasAccess';
import { Typography } from '@mui/material';
import useAuth from '../hooks/useAuth';
import Login from '../pages/authentication/Login';

interface AuthGuardProps {
  children: ReactNode;
  unauthorizedBehaviour?: EnAuthGuardBehaviour;
  minimumUserRole?: UserRole;
  fallback?: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = (props) => {
  const { children, unauthorizedBehaviour, minimumUserRole, fallback } = props;
  const { isAuthenticated, user } = useAuth();
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);
  const hasAccess = useHasAccess(minimumUserRole, user ? user.role : [UserRole.Anonymous]);

  const loginBehaviour = () => {
    if (!isAuthenticated) {
      if (location.pathname !== requestedLocation) {
        setRequestedLocation(location.pathname);
      }

      return <Login />;
    }

    // This is done so that in case the route changes by any chance through other
    // means between the moment of request and the render we navigate to the initially
    // requested route.
    if (requestedLocation && location.pathname !== requestedLocation) {
      setRequestedLocation(null);
      return <Navigate to={requestedLocation} />;
    }

    return <Typography sx={{ pt: 10, pl: 3, pb: 10 }}>Nicht berechtigt</Typography>;
  };

  if (isAuthenticated && hasAccess) {
    return <>{children}</>;
  }

  switch (unauthorizedBehaviour) {
    case EnAuthGuardBehaviour.Login:
      return loginBehaviour();
    case EnAuthGuardBehaviour.InfoMessage:
      return <Typography sx={{ pt: 10, pl: 3, pb: 10 }}>Nicht berechtigt</Typography>;
    case EnAuthGuardBehaviour.Fallback:
      return <>{fallback}</>;
    default: // hide
      return <></>;
  }
};

AuthGuard.defaultProps = {
  unauthorizedBehaviour: EnAuthGuardBehaviour.Login,
  minimumUserRole: UserRole.User
};

export default AuthGuard;
